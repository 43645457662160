export default function preLoader() {
  const preloader = document.getElementById('preLoader');
  if (!preLoader) return;

  const preloadLogo = document.getElementById('preloadLogo');

  window.addEventListener('load', showPreloader);

  function showPreloader() {

    // sessionStorage.clear();
    if (!sessionStorage.isVisited) {
      sessionStorage.isVisited = 'true';
      longPreloader();
    } else {
      shortPreloader();
    }

    function longPreloader() {
      var els = preloadLogo.getElementsByTagName("animate");

      [...els].forEach(el => el.beginElement());

      setTimeout(() => {
        preloadLogo.classList.add('hidden');

        setTimeout(() => {
          preloader.classList.add('hidden');
        }, 100);

        setTimeout(() => {
          preloader.classList.add('invisible');
        }, 2000);
      }, 500);
    }

    function shortPreloader() {
      setTimeout(() => {
        preloader.classList.add('hidden');
      }, 100);
      setTimeout(() => {
        preloader.classList.add('invisible');
      }, 200);
    }


  }

}