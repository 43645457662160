import all from "../shared/utilities";

import preLoader from "../shared/preloader";
import ajaxLoad from "../shared/ajax-load";
import slider from "../shared/slider";
import openFilter from "../shared/filters";
import formSent from "../shared/form";
import onScroll from "../shared/onscroll";
import select from "./modules/select";
import dropMenu from "./modules/drop-menu";
import videoPapUp from "../shared/video-popup";
import historyBack from "./modules/history-back";
import dataSession from "../shared/session";
import search from "../shared/search";
import toggleMain from "../shared/toggle-main";
import oklifSlider from "../shared/oklif-slider";


document.addEventListener('DOMContentLoaded', e => {
    preLoader();
    slider();
    ajaxLoad();
    openFilter();
    onScroll();
    select();
    dataSession();
    dropMenu();
    // productsFilter();
    search();
    toggleMain();
    historyBack();
    videoPapUp();
    oklifSlider();
    window.addEventListener('load', formSent);
});



















