import 'slick-carousel'
export default function oklifSlider() {

  let section = document.querySelector('.oklif-slider');
  if (!section) return;

  let nav = section.querySelector('.slider-nav');
  let nextButton = nav.querySelector('button.next');
  let prevButton = nav.querySelector('button.prev');

  let sliderBig = $('#oklifSliderBig');
  let sliderSmall = $('#oklifSliderSmall');

  sliderBig.slick({

    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: true,
    dotsClass: 'site-dots',
    centerMode: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          variableWidth: true,
          draggable: true,
          fade: false,
        }
      }
    ]

  });

  sliderSmall.slick({
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 1,
    dots: false,
    draggable: false
  });

  nextButton.addEventListener('click', () => {
    sliderBig.slick('slickNext');
    sliderSmall.slick('slickNext');
  })
  prevButton.addEventListener('click', () => {
    sliderBig.slick('slickPrev');
    sliderSmall.slick('slickPrev');
  })

}
