import { preloaderStart, preloaderStop } from "./ajaxPreloader";

export default function ajaxLoad() {

    const blog = document.querySelector('section.blog');
    if (!blog) return;

    let container = blog.querySelector('.news');
    let topTags = blog.querySelectorAll('.tags-all a');
    let tags;
    let firstNews = blog.querySelector('.first-news');
    let loadMore = blog.querySelector('.load-more');

    let activeTags = [];
    let reset = blog.querySelector('.reset');
    let paged = 1;



    filterByTags();
    singleTags();
    hoverLinks();

    if (loadMore) {
        loadMore.addEventListener('click', () => {
            paged++;
            loadOnClick();
        });
    }

    // Filter posts by tags
    function filterByTags() {
        tags = blog.querySelectorAll('a[data-tag]');

        if (topTags) {
            [...topTags].forEach(tag => {
                tag.addEventListener('click', e => {
                    e.preventDefault();
                    onTagClick(tag);
                });
            });
        }
    }

    // Tags in posts thumbs
    function singleTags() {

        tags = container.querySelectorAll('a[data-tag]');

        if (tags) {
            [...tags].forEach(tag => {
                tag.addEventListener('click', e => {

                    e.preventDefault();
                    activeTags = [];
                    onTagClick(tag);
                })
            })
        }
    }

    // When clicked on tag
    function onTagClick(tag) {

        paged = 1;
        let tagId = tag.getAttribute('data-tag');

        if (tagId == -1) {
            if (activeTags.length) {
                activeTags = [];
            } else {
                return;
            }
        }

        else {
            (activeTags.includes(tagId))
                ? activeTags = activeTags.filter(id => id != tagId)
                : activeTags.push(tagId);
        }
        toggleTags();
        loadOnClick();
    }

    // Toggle tags active state
    function toggleTags() {

        [...topTags].forEach(tag => {
            tag.classList.toggle('active', activeTags.includes(tag.getAttribute('data-tag')));
        });

        reset.classList.toggle('active', activeTags.length > 0);
    }

    // Load posts via ajax
    function loadOnClick() {

        $.ajax({
            url: ABM.ajaxUrl,
            type: 'POST',
            data: {
                action: 'load_abm_posts',
                tags: activeTags,
                paged: paged
            },
            beforeSend: () => {
                preloaderStart(container);
            },
            success: response => {
                if (response) {

                    if (paged == 1) {
                        firstNews.style.display = 'none';
                        container.innerHTML = response;
                    } else {
                        container.innerHTML += response;
                    }
                    singleTags();
                    toggleLoadMore();
                    hoverLinks();
                }
                preloaderStop(container);
            },
        });
    }

    // Show/hide loadmore
    function toggleLoadMore() {

        if (loadMore) {

            let pageData = document.getElementById('ajaxData');
            let maxPages = 1;

            if (pageData) {
                maxPages = pageData.getAttribute('data-max-pages');
            }
            loadMore.classList.toggle('hidden', paged == maxPages);
        }
    }

    function hoverLinks() {
        let newsLink = blog.querySelectorAll('.new');
        newsLink.forEach(n =>{
            let a = n.querySelectorAll('a:not([data-tag])');
            a.forEach( l=>{
                l.addEventListener('mouseenter', function () {
                    a.forEach(e=> e.classList.add('hover'))
                });
                l.addEventListener('mouseleave', function () {
                    a.forEach(e=> e.classList.remove('hover'))
                })
            })
        })
    }

}