export default function videoPapUp() {

    let videoBtn = document.querySelector('.open-video-btn');
    let videoPopUp = document.querySelector('.video-pop-up');
    let closeVideoBtn = document.querySelector('.close-video span');


    if (videoBtn) {
        videoBtn.addEventListener('click', function () {

            if (videoBtn.classList.contains('loadYTApi')){
                let ifYTurl = videoBtn.getAttribute('data-src').split('.').includes("youtube");
                ifYTurl ? loadYTPlayer(closeVideoBtn) : createVideo(videoBtn.getAttribute('data-src'));
                videoBtn.classList.remove('loadYTApi');
            }
            videoPopUp.classList.add('active');
        });
        closeVideoBtn.addEventListener('click', ()=>closeVideo());
    }


    const closeVideo = () => {
        videoPopUp.classList.remove('active');
    };

    function createVideo(src) {
        let video = document.createElement("video");
        let source  = document.createElement("source");
        source.setAttribute('type', 'video/mp4');
        video.setAttribute('controls', 'controls');
        video.id = 'videoPlayer';
        source.src = src;

        video.append(source);
        videoPopUp.querySelector('.parentPlayer').append(video);

        video.play();

        videoBtn.addEventListener('click', e=> video.play());
        closeVideoBtn.addEventListener('click',e=> video.pause());

        document.addEventListener("keydown", function (e) {
            if (e.keyCode === 27) {
                closeVideo();
                video.pause()
            }
        });
    }

    function loadYTPlayer(btn) {
        let ytplayer;
        let postPlayer = document.getElementById('player');
        let youtubeAPIloaded = false;
        let id = videoBtn.getAttribute('data-src').split('/').pop();
        let parentPlayer = postPlayer.parentNode;

        parentPlayer.style.backgroundImage = "url('https://img.youtube.com/vi/" + id + "/maxresdefault.jpg')";

        loadPlayer();

        // Load post yPlayer
        function loadPlayer() {
            // Show yPlayer && load more
            window.addEventListener('load', ytPlayer());
        }

        // Youtube player
        function ytPlayer() {
            if (postPlayer) {
                // Load Youtube API (once)
                if (!youtubeAPIloaded) {
                    let tag = document.createElement('script');
                    tag.src = "https://www.youtube.com/iframe_api";
                    let firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                    youtubeAPIloaded = true;
                }
                // Create players list
                enqueueOnYoutubeIframeAPIReady(() => createPlayer());
            }
        }

        // Create player
        function createPlayer() {

            ytplayer = new YT.Player(postPlayer, {
                videoId: id,
                playerVars: {
                    'enablejasapi': 1,
                    'playlist': id,
                    'controls': 1,
                    'loop': 1,
                    'autoplay': 1,
                    'showinfo': 0,
                    'modestbranding': 1,
                    'iv_load_policy': 3,
                },
                events: {
                    'onReady': () => ytplayer.playVideo()
                }
            });
        }

        //close & pause video
        btn.addEventListener('click', ()=> {
            closeVideo();
            ytplayer.pauseVideo();
        });
        document.addEventListener("keydown", function (e) {
            if (e.keyCode === 27) {
                closeVideo();
                ytplayer.pauseVideo();
            }
        });

    }
// Trigger youtube iFrameAPI
    (function () {
        var isReady = false;
        var callbacks = [];

        window.enqueueOnYoutubeIframeAPIReady = function (callback) {
            if (isReady) {
                callback();
            } else {
                callbacks.push(callback);
            }
        };

        window.onYouTubeIframeAPIReady = function () {
            isReady = true;
            callbacks.forEach(function (callback) {
                callback();
            });
            callbacks.splice(0);
        }
    })();
}