import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export default function toggleMain() {
  const toggleBtn = document.getElementById('toggleMainBtn');
  const mainMenu = document.getElementById('mainMenu');

  toggleBtn.addEventListener('click', e => {
    mainMenu.classList.toggle('active');
    toggleBtn.classList.toggle('active');

    if(mainMenu.classList.contains('active')) {
      disableBodyScroll(document);
    } else {
      enableBodyScroll(document);
    }
  })

  const activeLinks = document.querySelector('[data-active-links]');
  let urls;

  if (activeLinks) {

    urls = activeLinks.dataset.activeLinks.split(',');

    toggleMenuItems();
    toggleCategoriesItems();
  }


  // Toggle active state for parent categories menu items
  function toggleMenuItems() {

    const menuLinks = document.querySelectorAll('.top-menu a, .main-menu__prod a');
    [...menuLinks].forEach(item => item.classList.toggle('active-link', urls.includes(item.href)));
  }

  // Toggle active categories in products page
  function toggleCategoriesItems() {
    const catPage = document.querySelector('.production-page');
    let catLinks = [];
    if (catPage) catLinks = catPage.querySelectorAll('.filters a');


    [...catLinks].forEach(link => {
      let parent = link.closest('.category');
      let childs = [...parent.querySelectorAll('a:not(.title)')];
      let all = parent.querySelector('[data-all]');

      childs.forEach((item, index) => {

        if (urls.includes(item.href)) {
          parent.classList.add('active');
        }

        item.classList.toggle('active', urls.includes(item.href) && index > 0);
      });

      if (all && all.href == urls[1]) {
        all.classList.add('active');
      }
    });
  }
}



