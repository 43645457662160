export default function select() {

    $('select').each(function () {

        let placeholderTxt = document.querySelector('[data-placeholder]').getAttribute('data-placeholder');

        // Variables
        let $this = $(this),
            selectOption = $this.find('option'),
            selectOptionLength = selectOption.length,
            dur = 0;

        $this.hide();
        // Wrap all in select box
        $this.wrap('<div class="select"></div>');
        // Style box
        $('<div>', {
            class: 'select__gap',
            html: $('<span>', {
                text: placeholderTxt
            })
        }).insertAfter($this);



        const selectGap = $this.next('.select__gap'),
            caret = selectGap.find('.caret');
        // Add ul list
        $('<ul>', {
            class: 'select__list'
        }).insertAfter(selectGap);

        const selectList = selectGap.next('.select__list');
        // Add li - option items
        for (let i = 0; i < selectOptionLength; i++) {
            $('<li>', {
                class: 'select__item',
                html: $('<span>', {
                    text: selectOption.eq(i).text()
                })
            })
                .attr('data-value', selectOption.eq(i).val())
                .appendTo(selectList);
        }
        // Find all items
        const selectItem = selectList.find('li');

        selectList.slideUp(0);
        $(document).mouseup(function (e) {
            if ($('.select__gap, .select__list').has(e.target).length === 0 && selectGap.hasClass('on')) {
                selectList.hide();
                selectGap.removeClass('on');
            }
        });
        document.getElementById('form').addEventListener('wpcf7mailsent', e => {
            selectGap.html($('<span>', {
                text: placeholderTxt
            }))
        }, false);
        selectGap.on('click', function () {
            if (!$(this).hasClass('on')) {
                $(this).addClass('on');
                selectList.slideDown(dur);

                selectItem.on('click', function () {
                    const chooseItem = $(this).data('value');

                    $('select').val(chooseItem).attr('selected', 'selected');
                    selectGap.html( $('<p>', {text: $(this).find('span').text() }));

                    selectList.slideUp(dur);
                    selectGap.removeClass('on');
                });

            } else {
                $(this).removeClass('on');
                selectList.slideUp(dur);
            }
        });


    });

}