import { preloaderStart, preloaderStop } from "./ajaxPreloader";

export default function productsFilter() {

    const container = document.getElementById('postsContainer');
    const filters = document.querySelectorAll('.onClick');

    // open/close sub-filters
    filters.forEach(f => f.addEventListener('click', function () { openFilter(f) }));
    function openFilter(e) {
        e.parentNode.classList.toggle('active');
    }

    if (!container) return;

    const taxes = document.querySelectorAll('a[data-tax]');
    const loadMore = document.getElementById('loadMore');
    const seriesFilters = document.querySelectorAll('.tags-series .s');
    const animalFilters = document.querySelectorAll('.tags-pr a[data-type="product_animals"]');

    let dataContainer;
    let ajaxData;
    let allToggle = document.querySelector('.tags-pr a.all');
    let activeAnimals = [];
    let paged = 1;

    // Taxonomies filters
    [...taxes].forEach(a => {
        a.addEventListener('click', (e) => {
            e.preventDefault();
            loadProducts(a);
        });
    });

    readData();
    toggleAnimalsFilter();

    // Loadmore products
    if (loadMore) {
        loadMore.addEventListener('click', loadMoreProducts);
    }

    // Get ajaxData from div
    function readData() {

        dataContainer = document.getElementById('ajaxData');
        activeAnimals = [dataContainer.getAttribute('data-animals')];

        ajaxData = {
            'product_cat': dataContainer.getAttribute('data-category'),
            'product_series': dataContainer.getAttribute('data-series'),
            'product_animals': dataContainer.getAttribute('data-animals'),
            'paged': dataContainer.getAttribute('data-page')
        }
    }

    // Toggle all animals filters
    function toggleAllAnimals() {

        if (!allToggle || [...animalFilters].length == 2) return;

        allToggle.classList.toggle('active', activeAnimals.length == ([...animalFilters].length - 1) || !activeAnimals.length);

        if (activeAnimals.length > 1) {
            activeAnimals = activeAnimals.filter(el => el !== '-1');
        }

        if (!activeAnimals.length) {
            activeAnimals = ['-1'];
        }
    }

    // Toggle animals filters
    function toggleAnimalsFilter(id) {

        if (!id) {
            activeAnimals = ajaxData['product_animals'].split(',');
        }

        // Add / remove from array
        if (id && !activeAnimals.includes(id)) {
            if (id == '-1') {
                activeAnimals = ['-1'];
            } else {
                activeAnimals.push(id);
            }
        } else {
            if (id == '-1') {
                activeAnimals = ['-1'];
            } else {
                activeAnimals = activeAnimals.filter(el => el !== id);
            }
        }

        toggleAllAnimals();

        // Toggle active state
        [...animalFilters].forEach(el => {
            let id = el.getAttribute('data-id');
            el.classList.toggle('active', activeAnimals.includes(id));
        });

        // Save to object
        ajaxData['product_animals'] = activeAnimals.toString();
    }

    // Toggle series filters
    function toggleSeriesFilter(id) {
        [...seriesFilters].forEach(s => s.classList.toggle('active', s.getAttribute('data-id') == id));
    }

    // Filter products
    function loadProducts(a) {

        paged = 1;

        let type = a.getAttribute('data-type');
        let taxValue = a.getAttribute('data-id');

        // Sort by series
        if (type === 'product_series') {
            toggleSeriesFilter(taxValue);
            ajaxData['product_series'] = taxValue;
        }

        // Sort by animals
        if (type === 'product_animals') {
            toggleAnimalsFilter(taxValue);
            // ajaxData['product_series'] = "";
        }

        // Load products with ajax
        $.ajax({
            url: ABM.ajaxUrl,
            type: 'POST',
            data: {
                action: 'abm_filter_products',
                data: ajaxData
            },
            beforeSend: () => {
                preloaderStart(container);
            },
            success: response => {

                if (response) {
                    container.innerHTML = response;

                    let data = document.getElementById('ajaxData');
                    let maxPages = data.getAttribute('data-max-pages');

                    toggleLoadMore(maxPages > paged);
                }
                preloaderStop(container);
            }
        });
    }

    // Load more products with ajax
    function loadMoreProducts() {

        readData();
        paged++;
        ajaxData['paged'] = paged;

        $.ajax({
            url: ABM.ajaxUrl,
            type: 'POST',
            data: {
                action: 'abm_loadmore_products',
                data: ajaxData
            },
            beforeSend: () => {
                preloaderStart(container);
            },
            success: response => {

                if (response) {
                    container.innerHTML += response;

                    let data = document.getElementById('ajaxData');
                    let maxPages = data.getAttribute('data-max-pages');

                    toggleLoadMore(maxPages > paged);
                }
                preloaderStop(container);
            },
        });
    }

    // Toggle loadmore button
    function toggleLoadMore(force) {

        if (loadMore) {

            if (force) {
                let maxPages = document.getElementById('ajaxData').getAttribute('data-max-pages');
                loadMore.classList.toggle('hidden', paged == maxPages);
            }

            else {
                loadMore.classList.add('hidden');
            }
        }

    }
}