export default function dataSession() {

  const links = document.querySelectorAll('a[data-animals]');

  if (links) {

    [...links].forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        $.ajax({
          url: ABM.ajaxUrl,
          type: 'POST',
          data: {
            action: 'save_abm_session',
            id: link.getAttribute('data-animals'),
          },
          success: response => {
            // if (response) {
              // console.log(response);
              window.location = link.href;
            // }
          },
        });
      });
    });

  }

}