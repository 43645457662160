const preloader = document.getElementById('preLoader');
const preloadLogo = document.getElementById('preloadLogo');


function preloaderStart(container) {

  container.classList.add('ajax');
  preloader.classList.remove('invisible');
  preloader.classList.remove('hidden');
  preloadLogo.classList.remove('hidden');
  preloader.classList.add('ajax');

  var els = preloadLogo.getElementsByTagName("animate");

  [...els].forEach(el => el.beginElement());


}

function preloaderStop(container) {
    preloadLogo.classList.add('hidden');

    setTimeout(() => {
      preloader.classList.add('invisible');
    }, 500);

    container.classList.remove('ajax');
}

export {preloaderStart, preloaderStop};