import 'slick-carousel'
export default function slider() {

    let reviews = $('#homeWelcomeSlider');
    let productPhoto = document.querySelectorAll('#productSlider .image');

    if (reviews) {
        reviews.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            dotsClass: 'site-dots',
            fade: true,
            draggable: false,
            autoplay: true,
            autoplaySpeed: 4000,

        });
        reviews.slick('slickPause');
        setTimeout(e=>{reviews.slick('slickPlay')}, 1000)

    }

    if (productPhoto.length >= 2) {
        let product = $('#productSlider');
        product.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            prevArrow: $('#prevArr'),
            nextArrow: $('#nextArr'),
            dotsClass: 'site-dots',
            fade: true,
            draggable: false,
        });
    } else {
        if (productPhoto.length) document.querySelector('.img-block').classList.add('slider-none');
    }
}
