export default function dropMenu() {
    const topMenu = document.querySelector('.top-menu');
    const dropBtn = topMenu.querySelector('.dropdown a');
    const dropMain = document.querySelector('.dropdown-main');
    const hideDropLinks = document.querySelectorAll('.top-menu li');
    const dropMainCont = dropMain.querySelector('.main-menu__prod');

    setTimeout(()=>{
        dropMainCont.style.paddingLeft = dropBtn.offsetLeft + 'px';
    }, 500);

    hideDropLinks.forEach(l=>{
        l.addEventListener('mouseenter', e=>{
            dropMain.classList.remove('active')
        })
    });

    dropBtn.addEventListener('mouseenter', e=>{
        dropMain.classList.add('active')
    });
    dropMain.querySelector('.columns').addEventListener('mouseleave', e=>{
        dropMain.classList.remove('active')
    })

}