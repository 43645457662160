import autosize from 'autosize';
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export default function formSent() {
    let form = document.getElementById('form');
    let eventForm = document.getElementById('eventForm');

    if (!form) return;

    let personalForm = document.getElementById('personalForm');
    let personalBtn = document.querySelectorAll('.togglePersonForm');
    let popUp = document.querySelector('.pop-up');
    let events = document.querySelector('.events-pop-up');

    if (personalForm) {
        let pName = document.getElementById('personName'),
            pDesc = document.getElementById('personDesc'),
            pIcon = document.getElementById('personIcon'),
            pEmail = document.querySelector('input.wpcf7-hidden');

        personalBtn.forEach(b => {
            b.addEventListener('click', e => {
                toggleClass(personalForm);
                if (personalForm.classList.contains('active')) togglePersonalForm(b);
            })
        });

        function togglePersonalForm(btn) {
            let icon = btn.getAttribute('data-icon');
            let name = btn.getAttribute('data-call');
            let desc = btn.parentNode.querySelector('.desc').textContent;
            let email = btn.getAttribute('data-email');

            pIcon.src = icon;
            pName.textContent = name;
            pDesc.innerText = desc;
            pEmail.value = email;
        }
    }

    form.addEventListener('wpcf7mailsent', e => {
        toggleClass(popUp);
        if (personalForm) toggleClass(personalForm);
        document.querySelector('textarea').style.height = '';

    }, false);

    popUp.querySelectorAll('.close, .button').forEach(b => {
        b.addEventListener('click', e => toggleClass(popUp));
    });

    if (eventForm) eventPopup();

    function eventPopup() {

        const hideButton = events.querySelector('.close-form');
        const run = window.location.hash.substr(1);

        if (run === 'event') toggleClass(events);

        eventForm.addEventListener('wpcf7mailsent', e => {
            toggleClass(popUp);
            hidePopup()
        }, false);

        hideButton.addEventListener('click', hidePopup);

        function hidePopup() {
            toggleClass(events);
            history.replaceState(null, null, ' ');
        }
    }

    function toggleClass(el) {
        el.classList.toggle('active')
        if (el.classList.contains('active')) {
            disableBodyScroll(document);
        } else {
            enableBodyScroll(document);
        }
    }

    autosize(document.querySelectorAll('textarea'));
}