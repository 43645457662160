// import _, { debounce } from 'underscore';

const searchBtn = document.getElementById('searchBtn');
const closeBtn = document.getElementById('closeBtn');
const searchBar = document.getElementById('searchBar');
const input = searchBar.getElementsByTagName('input')[0];
const results = searchBar.querySelector('.results');
const notRes = searchBtn.getAttribute('data-not-res');
let value = '';
let xhr;

//suspend scroll
$(results).on('mousewheel DOMMouseScroll', function (e) {

  let e0 = e.originalEvent;
  let delta = e0.wheelDelta || -e0.detail;

  this.scrollTop += (delta < 0 ? 1 : -1) * 30;
  e.preventDefault();
});

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms)
  };
}


export default function search() {

  // open search input
  searchBtn.addEventListener('click', e => {
    searchBar.classList.add('active');
    input.value = '';
  });

  // close search input
  document.addEventListener('click', function (e) {
    if (!searchBar.contains(e.target) || closeBtn.contains(e.target)) {
      searchBar.classList.remove('active');
      results.innerHTML = '';
    }
  });
  // closeBtn.addEventListener('click', e => {
  //   searchBar.classList.remove('active');
  // });

  // search on input
  input.addEventListener('keyup', debounce((e) => {

    value = input.value.trim();

    if (value.length > 2) {
      ajaxSearch(value);
    } else {
      results.innerHTML = '';
    }
  }, 300));
}

// Search text via ajax
function ajaxSearch(str) {

  if (xhr) {
    xhr.abort();
    xhr = null;
  }

  xhr = $.post(ABM.ajaxUrl, {
    action: 'search',
    text: str,
  }, (response) => {
    results.innerHTML = response ? '<div>' + response : '<div> <p>' + notRes;
  });
}

